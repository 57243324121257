@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600&family=Mulish:wght@200;400;500;600;700&family=Nunito+Sans:ital,wght@0,200;0,300;0,600;0,700;1,400&display=swap");

* {
  font-family: "Monserrat", sans-serif;
}

.window {
  margin-top: 24px;
}

.title-bar-text {
  font-size: 14px;
  margin-left: 4px;
}

a {
  text-decoration: none;
  color: black;

}

span {
  font-family: "Monserrat", sans-serif;
}

#quill-editor {
  background-color: white;
}

.quill {
  background-color: white;
  width: 75%;
  min-height: 300px;
  max-height: 325px;
  padding: 8px;
  overflow: auto;
}

.quill a {
  border: none;
  color: black;
}

.ql-toolbar {
  width: 100%;
  border: 1px;
  border-color: black;
  margin-bottom: 1px;
  zoom: 85%;
  align-items: center;
}

.ql-toolbar button {
  width: 10px;
}

body {
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* .editor-block {
  background-color: white;
  width: 75%;
  text-align: center;
  overflow: auto;
  padding: 8px;
  border-radius: 5px;
} */

.search-users {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 8px;
}


.output {
  background-color: transparent;
  width: 75%;
  overflow: AUTO;
  padding: 16px;
  border-radius: 5px;
  margin-top: 16px;
  text-align: center;
}

.input-field {
  width: 100%;
  overflow: AUTO;
}

.hidden {
  display: none;
}


.login-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 98.5%;
 }
 

@media screen and (max-width: 350px) {
  a {
    font-size: 10px;
  }
  .save-btn {
    font-size: 10px;
    width: 95%;
  }
  .editor-title {
    font-size: 12px;
  }
  .input-field {
    font-size: 10px;
    width: 100%;
  }
  .output {
    font-size: 10px;
    width: 95%;
  }
  .editor-block {
    width: 95%;
  }
  .navlinks {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    width: 95%;
  }
  .search-user {
    display: flex;
    flex-direction: column;
    width: 95%;
    gap: 0px;
  }
  .quill {
    width: 95%;
  }
  .ql-toolbar {
    width: 95%;
  }
}
