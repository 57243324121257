@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600&family=Mulish:wght@200;400;500;600;700&family=Nunito+Sans:ital,wght@0,200;0,300;0,600;0,700;1,400&display=swap";
* {
  font-family: Monserrat, sans-serif;
}

.window {
  margin-top: 24px;
}

.title-bar-text {
  margin-left: 4px;
  font-size: 14px;
}

a {
  color: #000;
  text-decoration: none;
}

span {
  font-family: Monserrat, sans-serif;
}

#quill-editor {
  background-color: #fff;
}

.quill {
  width: 75%;
  min-height: 300px;
  max-height: 325px;
  background-color: #fff;
  padding: 8px;
  overflow: auto;
}

.quill a {
  color: #000;
  border: none;
}

.ql-toolbar {
  width: 100%;
  zoom: 85%;
  border: 1px #000;
  align-items: center;
  margin-bottom: 1px;
}

.ql-toolbar button {
  width: 10px;
}

body {
  background-color: #f2f2f2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.search-users {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8px;
  display: flex;
}

.output {
  width: 75%;
  text-align: center;
  background-color: #0000;
  border-radius: 5px;
  margin-top: 16px;
  padding: 16px;
  overflow: auto;
}

.input-field {
  width: 100%;
  overflow: auto;
}

.hidden {
  display: none;
}

.login-menu {
  width: 98.5%;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

@media screen and (max-width: 350px) {
  a {
    font-size: 10px;
  }

  .save-btn {
    width: 95%;
    font-size: 10px;
  }

  .editor-title {
    font-size: 12px;
  }

  .input-field {
    width: 100%;
    font-size: 10px;
  }

  .output {
    width: 95%;
    font-size: 10px;
  }

  .editor-block {
    width: 95%;
  }

  .navlinks {
    width: 95%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    display: flex;
  }

  .search-user {
    width: 95%;
    flex-direction: column;
    gap: 0;
    display: flex;
  }

  .quill, .ql-toolbar {
    width: 95%;
  }
}

/*# sourceMappingURL=editprofile.895b1f20.css.map */
